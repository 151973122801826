/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}
img {
    display: block;
    max-width: 100%;
}
body {
    max-width: 1920px;
    margin: 0 auto;
}

.center {
    width: 1200px;
    margin: 0 auto;
}

.header {
    width: 100%;
    position: relative;
    z-index: 40;
    .head_nav {
        width: 100%;
        .logo {
            float: left;
            width: 20%;
            position: relative;
            height: 130px;
            img {
                display: block;
                margin: 0 auto;
            }
        }
        ul {
            li {
                height: 130px;
                width: 10%;
                float: left;
                line-height: 43px;
                position: relative;
                text-align: center;
                >a {
                    color: #333;
                    font-size: 16px;
                    line-height: 130px;
                    text-align: center;
                    display: block;
                }
                .v_list {
                    position: absolute;
                    width: 130%;
                    top: 130px;
                    left: 0;
                    z-index: 99;
                    display: none;
                    a {
                        background: #fff;
                        width: 100%;
                        color: #333;
                        line-height: 50px;
                        font-size: 14px;
                        transition: .5s;
                        padding-left: 25px;
                        text-align: left;
                        display: block;
                        border-left: 2px solid #fff;
                        &:hover {
                            background: #f7f7f7;
                            color: #333;
                            border-left: 2px solid #bd111d;
                        }
                        &:first-child {
                            border-top: 2px solid #bd111d;
                        }
                    }
                }
            }
        }
    }
}

.pc_banner {
    width: 100%;
    position: relative;
    z-index: 30;
    .pcbanner {
        width: 100%;
        overflow: hidden;
        .swiper-slide {
            img {
                width: 100%;
                display: block;
            }
        }
        .pagination {
            text-align: center;
            position: absolute;
            bottom: 10px;
            width: 100%;
            z-index: 99;
            span {
                width: 50px;
                height: 3px;
                border-radius: 0;
                display: inline-block;
                text-indent: -999px;
                overflow: hidden;
                overflow: hidden;
                cursor: pointer;
                background: #eee;
                margin: 0 3px;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                    background: #bd111d;
                    color: #fff;
                }
            }
        }
    }
}

.m_header {
    width: 100%;
    overflow: hidden;
    display: none;
    .m_head_content {
        height: 60px;
        width: 100%;
        position: fixed;
        background: #fff;
        top: 0;
        left: 0;
        z-index: 99;
        box-shadow: 0px 1px 10px 1px #ccc;
        .m_head_logo {
            height: 60px;
            float: left;
            padding-left: 10px;
            a {
                float: left;
                img {
                    height: 52px;
                    display: block;
                    margin: 0 auto;
                    margin-top: 4px;
                }
            }
            p {
                float: left;
                padding-top: 13px;
                padding-left: 8px;
                span {
                    color: #bd111d;
                    font-size: 19px;
                    line-height: 25px;
                    font-weight: bold;
                    display: block;
                    text-align: left;
                }
                i {
                    color: #bd111d;
                    font-size: 12px;
                    line-height: 18px;
                    font-style: normal;
                    display: block;
                }
            }
        }
        .menu {
            width: 60px;
            height: 60px;
            position: absolute;
            right: 0;
            top: 0;
            img {
                height: 26px;
                display: block;
                margin: 0 auto;
                margin-top: 17px;
            }
        }
    }
}

.app_menu {
    width: 100%;
    position: fixed;
    height: 100%;
    top: 60px;
    right: 0;
    z-index: 99;
    display: none;
    ul {
        background: #000;
        li {
            width: 100%;
            border-bottom: 1px dashed #333;
            >a {
                width: 50%;
                display: block;
                span {
                    font-size: 14px;
                    width: 100%;
                    height: 42px;
                    line-height: 42px;
                    color: #999899;
                    display: block;
                    padding-left: 18px;
                }
            }
            &.on {
                background: url(../img/bottom.png) no-repeat 95% 15px;
                background-size: 16px;
            }
        }
        .v_list {
            width: 100%;
            display: none;
            a {
                display: block;
                font-size: 14px;
                width: 100%;
                line-height: 36px;
                color: #999899;
                display: block;
                padding-left: .4rem;
                float: none;
            }
        }
    }
}

.m_banner {
    display: none;
    width: 100%;
    overflow: hidden;
    .mbanner {
        position: relative;
        .swiper-slide {
            img {
                width: 100%;
                display: block;
            }
        }
        .pagination {
            text-align: center;
            position: absolute;
            bottom: 5px;
            width: 100%;
            z-index: 90;
            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: inline-block;
                text-indent: -999px;
                overflow: hidden;
                overflow: hidden;
                cursor: pointer;
                background: #eee;
                margin: 0 4px;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                    background: #bd111d;
                    color: #fff;
                }
            }
        }
    }
}

.main {
    width: 100%;
    overflow: hidden;
} 

.i_box1 {
    width: 100%;
    padding: 75px 0;
    background: url(../img/bg1.jpg) no-repeat top center;
    .box {
        width: 100%;
        .fl {
            width: 31.4%;
            border: 2px solid #bd111d;
            padding: 50px 35px;
            span {
                display: block;
                font-size: 50px;
                color: #333;
                line-height: 100%;
            }
            i {
                width: 80px;
                height: 3px;
                background: #bd111d;
                font-style: normal;
                display: block;
                margin-top: 30px;
            }
            em {
                font-size: 24px;
                color: #bd111d;
                display: block;
                font-style: normal;
                line-height: 100%;
                margin-top: 45px;
            }
            p {
                font-size: 16px;
                color: #666;
                display: block;
                line-height: 180%;
                margin-top: 25px;
                padding-right: 10%;
            }
            a {
                font-size: 16px;
                color: #fff;
                width: 160px;
                line-height: 42px;
                border-radius: 10px;
                padding-left: 25px;
                display: block;
                margin-top: 45px;
                background: url(../img/jt.png) no-repeat 105px center #bd111d;
            }
        }
        .fr {
            width: 67%;
            background: #fff;
            padding: 40px;
            .fr_box {
                background: #bd111d;
            }
            .text {
                width: 33%;
                float: left;
                padding: 40px;
                background: url(../img/sanj.png) no-repeat top center;
                p {
                    font-size: 16px;
                    color: #fff;
                    line-height: 160%;
                }
            }
            .pic {
                background: #fff;
                width: 67%;
                float: right;
                img {
                    display: block;
                    max-width: 100%;
                }
            }
        }
    }
}

.i_box2 {
    width: 100%;
    padding: 70px 0;
    background: url(../img/bg2.jpg) no-repeat top center #f7f7f7;
    .box {
        span {
            font-size: 50px;
            color: #333;
            display: block;
            text-align: center;
            line-height: 100%;
            padding-bottom: 50px;
        }
        p {
            font-size: 24px;
            color: #333;
            display: block;
            text-align: center;
            line-height: 180%;
            margin-top: 5px;
        }
    }
}

.i_box3 {
    width: 100%;
    height: 168px;
    background: url(../img/bg3.jpg) no-repeat top center;
    .box {
        text-align: center;
        padding-top: 59px;
        a {
            width: 200px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 16px;
            color: #333;
            @include inline-block();
            background: #fff;
            border-radius: 10px;
            &:first-child +a {
                margin: 0 150px;
            }
        }
    }
}

.box_tit {
    span {
        font-size: 50px;
        color: #333;
        display: block;
        text-align: center;
        line-height: 100%;
    }
    p {
        font-size: 14px;
        color: #999;
        display: block;
        text-align: center;
        margin-top: 20px;
    }
}

.i_box5 {
    width: 100%;
    padding: 75px 0;
    background: url(../img/bg4.png) no-repeat bottom center;
    .box {
        padding-top: 50px;
        a {
            width: 200px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 16px;
            color: #333;
            @include inline-block();
            background: #fff;
            border-radius: 10px;
            &:first-child +a {
                margin: 0 150px;
            }
        }
    }
}

.box5list {
    .preview {
        padding: 0 70px;
        .swiper-slide {
            width: 16.66%;
            float: left;
            cursor: pointer;
            .text {
                background: #f7f7f7;
                margin: 0 7px;
                padding: 17px 0;
                span {
                    width: 50px;
                    height: 50px;
                    display: block;
                    margin: 0 auto;
                    background: #bd111d;
                    border-radius: 50%;
                    img {
                        &:first-child {}
                        &:first-child +img {
                            display: none;
                        }
                    }
                }
                p {
                    font-size: 16px;
                    color: #333;
                    display: block;
                    text-align: center;
                    font-weight: bold;
                    margin-top: 12px;
                }
            }
            i {
                height: 12px;
                background: none;
                display: block;
                font-style: normal;
            }
        }
        .active-nav {
            .text {
                background: #bd111d;
                span {
                    background: #fff;
                    img {
                        &:first-child {
                            display: none;
                        }
                        &:first-child +img {
                            display: block;
                        }
                    }
                }
                p {
                    color: #fff;
                }
            }
            i {
                background: url(../img/xia1.png) no-repeat top center;
            }
        }
    }
    .view {
        margin-top: 40px;
        .swiper-slide {
            width: 100%;
            background: #fff;
            float: left;
            .pic {
                width: 38%;
                float: left;
                img {
                    display: block;
                    max-width: 100%;
                }
            }
            .text {
                width: 62%;
                float: right;
                padding: 5% 8% 0 8%;
                em {
                    width: 80px;
                    height: 3px;
                    background: #bd111d;
                    font-style: normal;
                    display: block;
                    margin-top: 30px;
                    font-style: normal;
                }
                p {
                    font-size: 14px;
                    color: #666;
                    display: block;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 38px;
                }
            }
        }
    }
}

.i_box6 {
    width: 100%;
    padding: 75px 0;
    .box {
        padding-top: 60px;
        .fl {
            width: 50%;
            img {
                display: block;
                max-width: 100%;
            }
        }
        .fr {
            width: 50%;
            li {
                background: #bd111d;
                float: right;
                margin-left: 12px;
                margin-bottom: 12px;
                padding: 12px 10px;
                span {
                    font-size: 13px;
                    color: #d07275;
                    display: block;
                    i {
                        font-size: 30px;
                        color: #ebdfa5;
                        line-height: 30px;
                        padding-right: 6px;
                        font-style: normal;
                    }
                }
                p {
                    font-size: 16px;
                    color: #fff;
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }
}

.i_box7 {
    width: 100%;
    padding: 70px 0;
    background: url(../img/bg5.jpg) no-repeat top center;
    .box_tit {
        span {
            color: #fff;
        }
    }
    .box {
        padding-top: 65px;
    }
}

.box7list {
    .swiper-slide {
        .text {
            width: 80%;
            margin: 0 auto;
            padding: 10px;
            min-height: 400px;
            span {
                line-height: 70px;
                border: 1px solid #e2cb98;
                font-size: 20px;
                color: #fff;
                display: block;
                i {
                    font-size: 30px;
                    color: #e2cb98;
                    font-style: normal;
                    padding-left: 10px;
                    padding-right: 8px;

                }
            }
            p {
                font-size: 15px;
                color: #fff;
                display: block;
                line-height: 180%;
                margin-top: 12px;
            }
            &:hover {
                background: #bd111d;
            }
        }
    }
}

.i_box8 {
    width: 100%;
    height: 300px;
    background: url(../img/bg6.jpg) no-repeat top center;
    .box {
        padding: 0 26px;
        padding-top: 80px;
        span {
            font-size: 40px;
            color: #fff;
            display: block;
            text-align: center;
            line-height: 100%;
        }
        .text {
            margin-top: 50px;
            input {
                width: 27%;
                float: left;
                margin-right: 1%;
                height: 50px;
                border-radius: 5px;
                border: none;
                border: 1px solid #fff;
                background: none;
                padding: 0 10px;
                outline: 0;
                &::-webkit-input-placeholder {
                    color: #e7e7e7;
                    font-size: 16px;
                }
                &::-moz-placeholder {
                    color: #e7e7e7;
                    font-size: 16px;
                }
                &:-ms-input-placeholder {
                    color: #e7e7e7;
                    font-size: 16px;
                }   
            }
            .btn_sub {
                width: 16%;
                float: left;
                margin-right: 0;
                padding: 0;
                text-align: center;
                font-size: 16px;
                color: #fff;
                background: #bd111d;
                border-color: #bd111d;
            }
        }
    }
}

.i_box9 {
    width: 100%;
    padding: 75px 0;
    background: #f7f7f7;
    .box9_tit {
        .fl {
            span {
                font-size: 50px;
                color: #333;
                display: block;
                text-align: left;
                line-height: 100%;
            }
            p {
                font-size: 14px;
                color: #999;
                display: block;
                text-align: left;
                margin-top: 20px;
            }
        }
        .fr {
            a {
                width: 160px;
                height: 42px;
                border: 1px solid #cdcdcd;
                font-size: 16px;
                color: #333;
                display: block;
                text-align: center;
                line-height: 42px;
                float: left;
                border-radius: 5px;
                margin-left: 36px;
               &:hover {
                    background: #bd111d;
                    color: #fff;
                    border-color: #bd111d;
                }
               &.on {
                    background: #bd111d;
                    color: #fff;
                    border-color: #bd111d;
                }
            }
        }
    }
}

.box9_content {
    .hd {
        margin-top: -45px;
        ul {
            float: right;
        }
        li {
            float: left;
            margin-left: 36px;
            width: 160px;
            height: 42px;
            border: 1px solid #cdcdcd;
            font-size: 16px;
            color: #333;
            display: block;
            text-align: center;
            line-height: 42px;
            float: left;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background: #bd111d;
                color: #fff;
                border-color: #bd111d;
            }
            &.on {
                background: #bd111d;
                color: #fff;
                border-color: #bd111d;
            }
        }
    }
    .bd {
        margin-top: 50px;
    }
}

.box9list {
    position: relative;
    .swiper-slide {
        background: #fff;
        padding: 20px;
        .pic {
            width: 100%;
            height: 350px;
            line-height: 350px;
            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
            }
        }
        span {
            border-top: 1px solid #ffd7d7;
            text-align: center;
            font-size: 16px;
            color: #333;
            padding-top: 20px;
            line-height: 100%;
            display: block;
            margin-top: 5px;
        }
        a {
            max-width: 140px;
            height: 32px;
            border-radius: 20px;
            border: 1px solid #d1d1d1;
            display: block;
            text-align: center;
            line-height: 30px;
            margin: 0 auto;
            margin-top: 20px;
            &:hover {
                background: #bd111d;
                color: #fff;
                border-color: #bd111d;
            }
        }
    }
    .pagination {
        text-align: center;
        margin-top: 10px;
        display: none;
        .swiper-pagination-bullet-active {
            background: #bd111d;
        }
    }
    .right {
        position: absolute;
        right: -80px;
        top: 45%;
        z-index: 99;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: url(../img/ri.png) no-repeat center #bd111d;
        opacity: 1;
        background-size: 100%;
        cursor: pointer;
        outline: 0;
    }
    .left {
        position: absolute;
        left: -80px;
        top: 45%;
        z-index: 99;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: url(../img/LE.png) no-repeat center #bd111d;
        opacity: 1;
        background-size: 100%;
        cursor: pointer;
        outline: 0;
    }
}

.more {
    a {
        font-size: 16px;
        color: #fff;
        width: 160px;
        line-height: 42px;
        border-radius: 10px;
        padding-left: 25px;
        display: block;
        margin-top: 45px;
        background: url(../img/jt.png) no-repeat 105px center #bd111d;
        float: right;
    }
}

.i_box10 {
    width: 100%;
    padding: 70px 0;
    background: url(../img/bg7.jpg) no-repeat top center;
    .box {
        .fl {
            width: 40%;
            padding-top: 100px;
        }
        .fr {
            width: 60%;
            .box_tit {
                span {
                    text-align: left;
                }
                p {
                    text-align: left;
                }
            }
            .fr_tit {
                margin-top: 80px;
                p {
                    width: 45%;
                    float: left;
                    span {
                        font-size: 26px;
                        color: #333;
                        font-weight: bold;
                        display: block;
                        line-height: 30px;
                        i {
                            width: 30px;
                            height: 5px;
                            background: #e2cb98;
                            float: left;
                            margin-top: 12px;
                            margin-right: 8px;
                        }
                    }
                    em {
                        padding-left: 38px;
                        font-style: normal;
                        font-size: 16px;
                        color: #333;
                        display: block;
                        margin-top: 6px;
                    }
                }
            }
            .text {
                padding-top: 35px;
                padding-right: 13%;
                p {
                    font-size: 16px;
                    margin-top: 35px;
                    line-height: 180%;
                    color: #333;
                }
            }
        }
    }
}

.i_box11 {
    width: 100%;
    padding: 70px 0;
    background: #f7f7f7;
    .box {
        padding-top: 42px;
        .text {
            background: #bd111d;
            p {
                width: 50%;
                float: left;
                padding: 30px 55px;
                font-size: 14px;
                color: #333;
                line-height: 180%;
                color: #fff;
                &:first-child +p {
                    background: #fff;
                    color: #333;
                }
            }
        }
    }
}

.box11list {
    padding: 0 20px;
    position: relative;
    margin-top: 50px;
    .swiper-slide {
        position: relative;
        width: 33.33%;
        padding-right: 5px;
        a {
            width: 100%;
            display: block;
            overflow: hidden;
        }
        span {
            position: absolute;
            right: 5px;
            top: 30%;
            width: 60%;
            i {
                width: 30px;
                height: 100%;
                position: absolute;
                font-style: normal;
                right: 0;
                top: 0;
                min-height: 108px;
                background: url(../img/sle.png) no-repeat 7px center #dcd9d6;
            }
            em {
                font-size: 14px;
                color: #333;
                font-style: normal;
                padding: 35px 20px;
                background: #fff;
                border-left: 3px solid #bd111d;
                position: relative;
                display: block;
                right: -105%;
            }
        }
        &:hover {
            span {
                i {
                    display: none;
                }
                em {
                    right: 0;
                    transition: .5s;
                }
            }
        }
    }
    .pagination {
        text-align: center;
        margin-top: 10px;
        display: none;
        .swiper-pagination-bullet-active {
            background: #bd111d;
        }
    }
}

.i_box12 {
    width: 100%;
    padding-top: 50px;
    background: url(../img/bg8.jpg) no-repeat bottom center;
    .box {
        .fl {
            width: 31.5%;
            padding-top: 12%;
            span {
                font-size: 50px;
                color: #333;
                display: block;
                text-align: right;
                line-height: 100%;
            }
            p {
                text-align: right;
                font-size: 16px;
                color: #333;
                display: block;
                margin-top: 30px;
                font-weight: bold;
            }
            i {
                font-style: normal;
                font-size: 18px;
                color: #b50f0e;
                display: block;
                text-align: right;
                margin-top: 28px;
            }
        }
        .mid {
            width: 33%;
            float: left;
            img {
                float: right;
            }
        }
        .fr {
            padding: 2% 5% 0 2%;
            width: 35%;
            float: left;
            li {
                background: #bd111d;
                border-radius: 40px;
                position: relative;
                padding: 15px 0;
                padding-left: 90px;
                margin-top: 40px;
                span {
                    font-size: 30px;
                    color: #e2cb98;
                    position: absolute;
                    left: 40px;
                    top: 10px;
                }
                p {
                    font-size: 20px;
                    color: #fff;
                    display: block;
                }
                i {
                    font-style: normal;
                    font-size: 16px;
                    color: #fff;
                    display: block;
                    min-height: 22px;
                }
            }
        }
    }
}

.i_box13 {
    width: 100%;
    padding: 90px 0;
    background: url(../img/bg9.png) no-repeat left 300% #f7f7f7;
    .box13_tit {
        span {
            font-size: 50px;
            color: #333;
            display: block;
            text-align: left;
            line-height: 100%;
        }
        p {
            font-size: 14px;
            color: #999;
            display: block;
            text-align: left;
            margin-top: 20px;
        }
    }
}

.box13list {
    .hd {
        margin-top: -45px;
        ul {
            float: right;
            li {
                float: left;
                margin-left: 36px;
                width: 160px;
                height: 42px;
                border: 1px solid #cdcdcd;
                font-size: 16px;
                color: #333;
                display: block;
                text-align: center;
                line-height: 42px;
                float: left;
                border-radius: 5px;
                cursor: pointer;
                &.on {
                    background: #bd111d;
                    color: #fff;
                    border-color: #bd111d;
                }
            }
        }
    }
    .bd {
        margin-top: 60px;
        li {
            width: 32%;
            margin-left: 2%;
            float: left;
            .text {
                padding: 0 12px;
            }
            a {
                display: block;
                overflow: hidden;
                img {
                    width: 100%;
                    transform: scale(1);
                    transition: .5s;
                }
            }
            span {
                font-size: 18px;
                color: #333;
                display: block;
                font-weight: bold;
                line-height: 30px;
                margin-top: 10px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                i {
                    width: 20px;
                    height: 4px;
                    background: #bd111d;
                    border-radius: 5px;
                    float: left;
                    margin-right: 12px;
                    margin-top: 13px;
                }
            }
            p {
                padding: 5px 2% 0 32px;
                font-size: 16px;
                color: #666;
                display: block;
                line-height: 160%;
            }
            .time {
                border-top: 1px solid #e0e0e0;
                padding-top: 5px;
                margin-top: 20px;
                img {
                    float: left;
                    height: 9px;
                    margin-top: 10px;
                }
                em {
                    float: right;
                    font-style: normal;
                    font-size: 14px;
                    color: #a5a5a5;
                    line-height: 30px;
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:hover {
                a {
                    img {
                        transform: scale(1.1);
                        transition: .5s;
                    }
                }
            }
        }
    }
}

.i_box14 {
    width: 100%;
    padding: 65px 0;
    background: url(../img/bg10.jpg) no-repeat top center;
    .box_tit {
        span {
            color: #fff;
        }
        p {
            color: #fff;
            font-size: 18px;
        }
    }
    .box {
        padding: 30px;
        background: #fff;
        margin-top: 45px;
        .fl {
            width: 40%;
            span {
                font-size: 18px;
                color: #333;
                display: block;
                margin-top: 18px;
            }
            input {
                display: block;
                width: 100%;
                line-height: 50px;
                padding: 0 10px;
                margin-top: 20px;
                border: none;
                border: 1px solid #b2b2b2;
            }
            textarea {
                display: block;
                width: 100%;
                padding: 5px;
                margin-top: 20px;
                border: none;
                height: 120px;
                border: 1px solid #b2b2b2;
            }
            .btn_sub {
                border-color: #bd111d;
                max-width: 44%;
                background: #bd111d;
                font-size: 16px;
                color: #fff;
                text-align: center;
                border-radius: 10px;
            }
        }
        .fr {
            width: 50%;
            padding: 20px;
            border: 1px solid #b2b2b2;
            .text {
                li {
                    width: 33.33%;
                    float: left;
                    span {
                        height: 140px;
                        display: block;
                        img {
                            max-height: 135px;
                            display: block;
                            margin: 0 auto;
                        }
                        .limg {
                            padding-top: 35%;
                        }
                    }
                    p {
                        font-size: 14px;
                        color: #666;
                        display: block;
                        text-align: center;
                    }
                }
            }
            .text2 {
                border-top: 1px dashed #ddd;
                margin-top: 35px;
                padding-top: 30px;
                padding-left: 30px;
                p {
                    font-size: 16px;
                    color: #333;
                    display: block;
                    padding-left: 40px;
                    line-height: 40px;
                    font-weight: bold;
                    &:first-child {
                        background: url(../img/phone.png) no-repeat left center;
                        margin-bottom: 12px;
                    }
                    &:first-child +p {
                        background: url(../img/add.png) no-repeat left center;
                    }
                }
            }
        }
    }
}

.footer {
    width: 100%;
    overflow: hidden;
    padding: 40px 0;
    background: #201e1d;
    .box {
        img {
            display: block;
            margin: 0 auto;
        }
        p {
            font-size: 14px;
            color: #999;
            display: block;
            text-align: center;
            line-height: 24px;
            margin-top: 12px;
        }
    }    
}

.page_banner {
    width: 100%;
    overflow: hidden;
    line-height: 0;
    position: relative;
    .pic {
        display: block;
    }
    .page_menu {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        .text {
            width: 300px;
            margin: 0 auto;
            padding-top: 14%;
            h3 {
                font-size: 40px;
                color: #fff;
                display: block;
                text-align: center;
                line-height: 100%;
            }
            span {
                width: 200px;
                height: 1px;
                background: #fff;
                display: block;
                line-height: 100%;
                margin: 22px auto;
                text-align: center;
                i {
                    width: 40px;
                    height: 3px;
                    background: #bd111d;
                    display: block;
                    margin: 0 auto;
                    position: relative;
                    top: -1px;
                }
            }
            p {
                font-size: 18px;
                color: #fff;
                display: block;
                text-align: center;
                line-height: 100%;
            }
        }
    }
}

.pp_box1 {
    .box {
        .cat_menu {
            float: left;
            a {
                line-height: 80px;
                float: left;
                margin-right: 40px;
                border-bottom: 2px solid #fff;
                font-size: 16px;
                color: #333;
                font-weight: bold;
                &:hover {
                    border-color: #bd111d;
                    color: #bd111d;
                }
            }
        }
        .currson {
            float: right;
            p {
                width: 100%;
                a {
                    line-height: 80px;
                    font-size: 16px;
                    color: #333;
                    font-weight: bold;
                    &:hover {
                        color: #bd111d;
                    }
                }
            }
        }

    }
}

.pp_tit {
    text-align: center;
    span {
        font-size: 30px;
        color: #333;
        position: relative;
        &:before {
            position: absolute;
            top: 95%;
            right: 115%;
            content: '';
            width: 84px;
            height: 4px;
            background: url(../img/tit.png) no-repeat center;
        }
        &:after {
            position: absolute;
            top: 95%;
            left: 115%;
            content: '';
            width: 84px;
            height: 4px;
            background: url(../img/tit2.png) no-repeat center;
        }
    }
    p {
        font-size: 14px;
        color: #999;
        display: block;
        margin-top: 8px;
    }
}

.pp_box2 {
    padding: 50px 0 40px 0;
    background: url(../img/pbg1.jpg) no-repeat top center;
    .box {
        margin-top: 40px;
        background: #fff;
        padding: 22px;
        .fl {
            width: 62%;
            span {
                display: block;
                font-weight: bold;
                font-size: 24px;
                color: #bd111d;
                padding: 30px 0;
            }
            p {
                font-size: 16px;
                color: #666;
                display: block;
                line-height: 180%;
                margin-top: 8px;
                padding-right: 8%;
            }
            .pic {
                margin-top: 40px;
                img {
                    float: left;
                    padding-left: 12px;
                    width: 45%;
                    &:first-child {
                        padding-left: 0;
                        width: 55%;
                    }
                }
            }
        }
        .fr {
            width: 38%;
            padding-top: 5%;
            margin-top: 3px;
            padding-left: 12px;
            img {
                display: block;
                width: 100%;
            }
        }
    }
}

.pp_box3 {
    padding: 50px 0 70px 0;
    background: url(../img/pbg2.png) no-repeat left bottom;
    .box {
        margin-top: 40px;
        .fl {
            width: 50%;
            span {
                display: block;
                font-weight: bold;
                font-size: 24px;
                color: #bd111d;
                padding: 30px 0;
            }
            p {
                font-size: 16px;
                color: #333;
                display: block;
                line-height: 180%;
                margin-top: 8px;
            }
        }
        .fr {
            width: 50%;
            padding-left: 30px;
        }
    }
}

.pp_box4 {
    padding: 50px 0 70px 0;
    background: url(../img/pbg0.jpg);
    .box {
        margin-top: 40px;
        .fl {
            width: 50%;
        }
        .fr {
            width: 50%;
            position: relative;
            margin-top: 20px;
            background: #f7f7f7;
            padding: 40px 89px 30px 50px;
            h4 {
                font-size: 30px;
                color: #bd111d;
                display: block;
            }
            p {
                padding-bottom: 16px;
                border-bottom: 1px solid #dedede;
                span {
                    font-size: 18px;
                    color: #bd111d;
                    display: block;
                    margin-top: 23px;
                    line-height: 100%;
                    font-weight: bold;
                }
                i {
                    font-style: normal;
                    font-size: 16px;
                    color: #333;
                    display: block;
                    line-height: 25px;
                    margin-top: 5px;
                }
            }
            em {
                width: 10px;
                height: 150px;
                background: #bd111d;
                position: absolute;
                left: -5px;
                top: 30%;
                display: block;
                z-index: 9;
            }
        }
    }
}

.pp_box5 {
    padding: 50px 0 70px 0;
    background: url(../img/pbg3.jpg) no-repeat top  center;
    .box {
        margin-top: 70px;
    }
}

.pplist5 {
    overflow: hidden;
    position: relative;
    width: 100%;
    .swiper-slide {
        
    }
    .pagination {
        text-align: center;
        width: 100%;
        z-index: 99;
        margin-top: 25px;
        span {
            width: 80px;
            height: 5px;
            border-radius: 5px;
            display: inline-block;
            text-indent: -999px;
            overflow: hidden;
            overflow: hidden;
            cursor: pointer;
            background: #dadada;
            opacity: 1;
            margin: 0;
            margin-left: -3px;
            outline: 0;
            &.swiper-pagination-bullet-active {
                background: #bd111d;
                color: #fff;
            }
        }
    }
}

.pp_box6 {
    padding: 70px 0;
    background: #f7f7f7;
    .box {
        margin-top: 50px;
        .text {
            background: #bd111d;
            p {
                width: 50%;
                float: left;
                padding: 30px 55px;
                font-size: 14px;
                color: #333;
                line-height: 180%;
                color: #fff;
                &:first-child +p {
                    background: #fff;
                    color: #333;
                }
            }
        }
        .list {
            margin-top: 40px;
            li {
                float: left;
                background: #fff;
                width: 100%;
                margin-bottom: 20px;
                border-right: 2px solid #bd111d;
                .fl {
                    width: 75%;
                    position: relative;
                    img {
                        width: 100%;
                    }
                    span {
                        width: 10px;
                        height: 100px;
                        background: #bd111d;
                        position: absolute;
                        right: -5px;
                        top: 36%;
                        display: block;
                        z-index: 9;
                    }
                }
                .fr {
                    width: 25%;
                    padding-top: 6%;
                    i {
                        width: 38px;
                        height: 2px;
                        background: #de888e;
                        display: block;
                        font-style: normal;
                        margin: 40px auto;
                    }
                    p {
                        font-size: 16px;
                        color: #666;
                        display: block;
                        text-align: center;
                    }
                }
                &:nth-child(2n) {
                    border: none;
                    border-left: 2px solid #bd111d;
                    .fl {
                        float: right;
                    }
                    .fr {}
                }
            }
        }
    }
}

.im_box0 {
    padding: 50px 0 70px 0;
    background: url(../img/jbg1.jpg) no-repeat top center;
}

.jm_box1 {
    padding: 80px 0;
    background: url(../img/jbg2.jpg) no-repeat top center;
    .box {
        margin-top: 50px;
        li {
            width: 50%;
            float: left;
            padding: 10px;
            .li_box {
                position: relative;
                min-height: 270px;
                padding: 30px 14% 0 0;
                i {
                    font-size: 40px;
                    color: #bd111d;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 20px;
                    font-weight: bold;
                    margin-left: 40px;
                }
                .text {
                    padding-left: 110px;
                    span {
                        font-size: 24px;
                        color: #333;
                        font-weight: bold;
                    }
                    p {
                        font-size: 16px;
                        color: #666;
                        display: block;
                        line-height: 24px;
                        margin-top: 20px;
                    }
                }
            }
            &:first-child {
                .li_box {
                    background: url(../img/j1.jpg) no-repeat center #fff;
                }
            }
            &:first-child +li {
                .li_box {
                    background: url(../img/j2.jpg) no-repeat center #fff;
                }
            }
            &:first-child +li +li {
                .li_box {
                    background: url(../img/j3.jpg) no-repeat center #fff;
                }
            }
            &:first-child +li +li +li {
                .li_box {
                    background: url(../img/j4.jpg) no-repeat center #fff;
                }
            }
        }
    }
}

.jm_box2 {
    padding: 70px 0;
    background: url(../img/pbg0.jpg) no-repeat top center;
    .box {
        margin-top: 50px;
        li {
            width: 100%;
            float: left;
            margin-top: 70px;
            .fl {
                width: 50%;
                padding: 3% 8% 0 8%; 
                span {
                    display: block;
                    font-size: 24px;
                    color: #333;
                }
                i {
                    width: 27px;
                    height: 3px;
                    background: #bd111d;
                    display: block;
                    font-style: normal;
                    margin: 20px 0;
                }
                p {
                    font-size: 16px;
                    color: #666;
                    line-height: 170%;
                    margin-bottom: 5px;
                    em {
                        float: left;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background: #bd111d;
                        font-size: 14px;
                        color: #fff;
                        text-align: center;
                        line-height: 30px;
                        margin-right: 10px;
                    }
                }
            }
            .fr {
                width: 50%;
                padding-left: 20px;
                img {
                    max-width: 100%;
                }
            }
            &:nth-child(2n) {
                .fl {
                    float: right;
                }
                .fr {}
            }
        }
    }
}

#prolist {
    padding: 40px 0;
}
#prolist .box9_content .bd {
    margin-top: 0;
}
#prolist .box9list .swiper-wrapper {
    display: block;
}
#prolist .box9list .swiper-slide {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2%;
    float: left;
}
#prolist .box9list .swiper-slide:nth-child(3n) {
    margin-right: 0;
}
#prolist .box9list .swiper-slide .imgauto {
    height: 380px;
}

.pro_text1 {
    background: #f7f7f7;
    padding: 30px 0;
    .box {
        background: url(../img/probg1.jpg);
        padding: 45px;
        .fl {
            width: 50%;
            border: 1px solid #e8e8e8;
            img {
                margin: 0 auto;
            }
        }
        .fr {
            width: 50%;
            padding: 8% 5% 0 10%;
            h3 {
                font-size: 24px;
                color: #bd111d;
                display: block;
                font-weight: bold;
                padding-bottom: 16px;
            }
            p {
                font-size: 18px;
                color: #333;
                display: block;
                margin-top: 10px;
            }
        }
    }
}

.pro_text2 {
    background: #f7f7f7;
    padding-bottom: 40px;
    .box {
        background: url(../img/probg1.jpg);
        .text {
            padding: 45px;
            background: url(../img/probg2.png) no-repeat right bottom;
            span {
                font-size: 20px;
                color: #bd111d;
                display: block;
                font-weight: bold;
                padding-bottom: 16px;
            }
        }
    }
}

.content {
    padding-left: 5%;
    p {
        font-size: 14px;
        line-height: 24px;
        margin-top: 12px;
    }
    img {
        display: block;
        margin: 0 auto;
    }
}

#pro_fw {
    background: #f7f7f7;
    padding: 30px 0;
    .box {
        padding-top: 20px;
        background: url(../img/bg8.jpg) no-repeat bottom center;
    }
}

#artlist {
    background: #f7f7f7;
    padding: 40px 0;
    .bd {
        margin-top: 0;
        li {
            background: #fff;
            margin: 0;
            margin-bottom: 2%;
            margin-right: 2%;
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
}

.wh_main {
    background: url(../img/wbg1.jpg) no-repeat top center;
    padding: 30px 0;
    .wh_box {
        padding: 0 70px;
        background: url(../img/pbg0.jpg);
    }
}

.wh_box1 {
    padding: 70px;
    border-bottom: 1px solid #ddd;
    .box {
        margin-top: 40px;
    }
    .text {
        p {
            font-size: 16px;
            color: #666;
            line-height: 180%;
            display: block;
            margin-bottom: 10px;
        }
    }
    .pic {
        margin-top: 40px;
        span {
            width: 50%;
            float: left;
            padding-left: 5%;
            img {

            }

        }    
    }
}

.wh_box2 {
    padding: 70px;
    border-bottom: 1px solid #ddd;
    .text {
        margin-top: 40px;
        p {
            font-size: 16px;
            color: #666;
            line-height: 180%;
            display: block;
            margin-bottom: 10px;
        }
    }
    .pic {
        img {
            margin: 0 auto;
        }  
    }
}

.zs_main {
    background: url(../img/wbg1.jpg) no-repeat top center;
    padding: 30px 0;
    .box {
        padding: 70px;
        background: url(../img/pbg0.jpg);
    }
    .list {
        li {
            width: 100%;
            margin-bottom: 80px;
            .fl {
                width: 50%;
                padding-right: 5%;
                h4 {
                    font-size: 24px;
                    color: #333;
                    font-weight: bold;
                    display: block;
                }
                span {
                    font-size: 16px;
                    color: #999;
                    display: block;
                    margin-top: 3px;
                }
                em {
                    width: 25px;
                    height: 3px;
                    background: #bd111d;
                    display: block;
                    font-style: normal;
                    margin-top: 15px;
                    margin-bottom: 25px;
                }
                p {
                    font-size: 16px;
                    color: #666;
                    display: block;
                    margin-top: 8px;
                }
                i {
                    font-style: normal;
                    font-size: 16px;
                    color: #333;
                    display: block;
                    font-weight: bold;
                    margin-top: 20px;
                }
            }
            .fr {
                width: 50%;
                img {
                    float: right;
                }
            }
            .li_bg {
                padding-top: 85px;
                p {
                    width: 100%;
                    height: 1px;
                    background: #e5e5e5;
                    span {
                        position: relative;
                        height: 3px;
                        width: 36px;
                        top: -1px;
                        background: #bd111d;
                        margin: 0 auto;
                        display: block;
                    }
                }
            }
            &:nth-child(2n) {
                .fl {
                    float: right;
                    padding-right: 0;
                    padding-left: 5%;
                }
                .fr {
                    img {
                        float: left;
                    }
                }
            }
        }
    }
}

.cont_box1 {
    padding: 30px 0;
    background: url(../img/contbg.jpg) no-repeat bottom center;
    .box {
        background: #fff;
        padding: 30px 15px;
        .fl {
            width: 50%;
            img {}
        }
        .fr {
            width: 50%;
            li {
                width: 50%;
                float: left;
                margin-bottom: 100px;
                .pic {
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    img {
                        @include inline-block();
                    }
                }
                span {
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    color: #999;
                    margin-top: 5px;
                }
                i {
                    display: block;
                    margin: 12px auto;
                    width: 20px;
                    height: 4px;
                    background: #bd111d;
                    font-style: normal;
                }
                p {
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    color: #333;
                    margin-top: 5px;
                    line-height: 160%;
                }
            }
        }
    }
}

.message {
    padding: 0 70px;
    p {
        input {
            width: 32%;
            margin-left: 2%;
            float: left;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    input {
        width: 100%;
        height: 46px;
        background: #f7f7f7;
        border: none;
        margin-bottom: 25px;
        padding: 0 10px;
        outline: 0;
        display: block;
    }
    textarea {
        width: 100%;
        height: 130px;
        background: #f7f7f7;
        border: none;
        margin-bottom: 20px;
        padding: 8px;
        outline: 0;
        display: block;
    }
    .btn_sub {
        width: 45%;
        background: #bd111d;
        font-size: 16px;
        color: #fff;
        text-align: center;
        line-height: 46px;
        margin: 0 auto;
        margin-top: 60px;
    }
}

.dl_box1 {
    padding: 65px 0;
    background: url(../img/dbg1.jpg) no-repeat top center;
}

.dlbox1list {
    margin-top: 45px;
    .swiper-slide {
        width: 50%;
        padding: 55px 0;
        .item {
            width: 600px;
            float: right;
            position: relative;
            padding: 0 15px;
            padding-right: 80px;
        }
        .pic {
            width: 50px;
            height: 50px;
            display: block;
            background: #bd111d;
            border-radius: 50%;
            position: absolute;
            left: 15px;
            top: 0;
            img {
                &:first-child +img {
                    display: none;
                }
            }
        }
        .text {
            padding-left: 80px;
            span {
                font-size: 24px;
                color: #333;
                display: block;
                font-weight: bold;
            }
            p {
                font-size: 16px;
                line-height: 160%;
                color: #666;
                display: block;
                margin-top: 15px;
                min-height: 130px;
            }
        }
        &.on {
            .item {
                float: left;
            }
        }
        &:hover {
            background: #bd111d;
            .pic{
                background: #fff;
                img {
                    &:first-child {
                        display: none;
                    }
                    &:first-child +img {
                        display: block;
                    }
                }
            }
            .text {
                span {
                    color: #fff;
                }
                p {
                    color: #fff;
                }
            }
        }
    }
    .pagination {
        text-align: center;
        width: 100%;
        margin-top: 10px;
        display: none;
        span {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: inline-block;
            text-indent: -999px;
            overflow: hidden;
            overflow: hidden;
            cursor: pointer;
            background: #999;
            margin: 0 3px;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background: #bd111d;
                color: #fff;
            }
        }
    }
}

.dl_box2 {
    padding: 65px 0;
    background: url(../img/dbg2.jpg) no-repeat top center;
    .pp_tit {
        span {
            color: #fff;
            &:before {
                position: absolute;
                top: 95%;
                right: 115%;
                content: '';
                width: 84px;
                height: 4px;
                background: url(../img/tit3.png) no-repeat center;
            }
            &:after {
                position: absolute;
                top: 95%;
                left: 115%;
                content: '';
                width: 84px;
                height: 4px;
                background: url(../img/tit4.png) no-repeat center;
            }
        }
        p {
            color: #fff;
        }
    }
    .box {
        margin-top: 50px;
        background: #fff;
        padding: 40px 20px;
    }
}

.prd-v5-menu {
    overflow: hidden;
    border-left: 1px solid #ddd;
    margin-bottom: 20px;
    li {
        float: left;
        width: 25%;
        border: 1px solid #ddd;
        border-right: 0;
        margin-left: -1px;
        background-color: #f9f9f9;
        height: 70px;
        select {
            display: block;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            width: 100%;
            height: 100%;
            border: 0;
            font-size: 16px;
            color: #666;
            padding-left: 20px;
            cursor: pointer;
            background: url(../img/dic3.png) no-repeat 90% center;
            outline: 0;
        }
        button {
            display: block;
            line-height: 72px;
            height: 72px;
            border: 1px solid #bd111d;
            margin: -1px 0 -1px 0;
            padding: 0;
            width: 100%;
            background: #bd111d;
            color: #fff;
            cursor: pointer;
            font-size: 24px;
        }
    }
}

.prd-v5-map {
    border: 1px solid #ddd;
    .map-list {
        float: left;
        width: 28%;
        height: 550px;
        .swiper-container {
            width: 100%;
            height: 100%;
        }
        .swiper-slide {
            height: auto;
        }
        li {
            border-bottom: 1px solid #f9f9f9;
            outline: 0;
            a {
                padding: 30px 20px;
                display: block;
            }
            h4 {
                margin: 0;
                background: url(../img/dic1.png) no-repeat 0 5px;
                font-size: 19.45px;
                padding-left: 30px;
                font-weight: bold;
                color: #333;
            }
            p {
                margin: 15px 0 0;
                font-size: 15.5px;
                background: url(../img/dic2.png) no-repeat 0 2px;
                padding-left: 30px;
                color: #666;
            }
            &:last-child {
                border-bottom: 0;
            }
            &:hover {
                background-color: #f9f9f9;
                h4 {
                    color: #bd111d;
                }
            }
            &.active {
                background-color: #f9f9f9;
                h4 {
                    color: #bd111d;
                }
            }
        }
    }
    .map-container {
        border-left: 1px solid #ddd;
        padding: 20px;
        width: 72%;
        float: right;
        height: 550px;
    }
    .swiper-scrollbar-drag {
        
    }
}





























.news_list {
    width: 100%;
    padding: 20px;
    border: 1px solid #EBEBEB;
    ul {
        li {
            width: 100%;
            float: left;
            height: 38px;
            padding-top: 9px;
            border-bottom: 1px dashed #c6c6c6;
            margin-bottom: 8px;
            a {
                float: left;
                width: 60%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 14px;
                color: #666
            }
            p {
                float: right;
                font-size: 12px;
                color: #666
            }
            &:hover {
                a {
                    color: #036aac;
                }
            }
        }
    }
}


.show_pages {
    padding-top: 20px;
    p {
        font-size: 14px;
        line-height: 24px;
        color: #333;
        margin-top: 6px;
        a {
            font-size: 14px;
            line-height: 24px;
            color: #333;
        }
    }
}

.content_tit {
    h3 {
        font-size: 21px;
        color: #426100;
        padding: 3px 0;
        line-height: 1.7em;
        text-align: center;
        font-weight: normal;
    }
    p {
        width: 100%;
        text-align: center;
        padding: 10px 0 15px;
        span {
            line-height: 21px;
            font-size: 12px;
            color: #666;
            margin: 0 5px;
        }
    }
}

.content_des {
    width: 100%;
    border: 1px dotted #E5E5E5;
    padding: 5px;
    margin-bottom: 10px;
    text-indent: 24px;
    p {
        font-size: 13px;
        color: #868686;
        line-height: 24px;
    }
}

.pro_content {
    width: 100%;
    margin: 20px 0;
    .pro_pic {
        width: 50%;
        float: left;
    }
    .pro_text {
        width: 50%;
        float: left;
        padding-left: 2%;
        h3 {
            font-weight: normal;
            margin-top: 5px;
            line-height: 22px;
            font-size: 18px;
            color: #2662bb;
            padding-bottom: 10px;
            border-bottom: 1px dotted #ccc;
        }
        .text {
            width: 100%;
            margin-top: 12px;
            p {
                font-size: 14px;
                line-height: 24px;
                padding-bottom: 12px;
            }
            a {
                width: 100px;
                height: 32px;
                font-size: 14px;
                margin-top: 10px;
                display: block;
                color: #fff;
                line-height: 32px;
                background: url(../img/sanj.png) no-repeat 5px center #4576be;
                background-size: 16px;
                padding-left: 24px;
                &:hover {
                    background: url(../img/sanj.png) no-repeat 5px center #333;
                    background-size: 16px;
                }
            }
        }
    }
}

div.zoomDiv {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    border: 1px solid #ddd;
    display: none;
    text-align: center;
    overflow: hidden;
    font-size: 0;
    img{
        max-width: none;
    }
}

div.zoomMask {
    position: absolute;
    background: url(../img/mask.png) repeat scroll 0 0 transparent;
    cursor: move;
    z-index: 1;
    max-width: 200px!important;
    max-height: 200px!important;
}

.img-center {
    text-align: center;
    font-size: 0;
    img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }
}

.pc-slide {
    width: 100%;
    margin: 0 auto;
    .view {
        border: 1px solid #ddd;
        .swiper-slide {
            width: 100%;
            .imgauto {
                width: 100%;
                height: 350px;
                padding: 20px;
                box-sizing:border-box;
            }
        }
    }
    .preview {
        width: 100%;
        margin-top: 10px;
        position: relative;
        .swiper-container {
            width: 80%;
            margin: 0 10%;
        }
        .swiper-slide {
            width: 25%;
            padding: 5px;
            .imgauto {
                width: 100%;
                height: 80px;
                border: 1px solid #ddd;
                img {
                    display: block;
                    padding: 5px;
                }
            }
        }
        .active-nav {
            .imgauto {
                border: 1px solid #4576be;
            }
        }
        .arrow-left {
            background: url(../img/feel3.png) no-repeat left top;
            position: absolute;
            left: 10px;
            top: 50%;
            margin-top: -9px;
            width: 9px;
            height: 18px;
            z-index: 10;
        }
        .arrow-right {
            background: url(../img/feel4.png) no-repeat left bottom;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -9px;
            width: 9px;
            height: 18px;
            z-index: 10;
        }
    }
}

.page_title {
    border-bottom: 1px solid #036aac;
    padding-top: 15px;
    h3 {
        width: 140px;
        height: 42px;
        font-size: 18px;
        margin-top: 10px;
        display: block;
        color: #fff;
        line-height: 42px;
        background: #036aac;
        text-align: center;
        font-weight: normal;
    }
}

.page_content {
    width: 100%;
    overflow: hidden;
}















.iet {
    height: 30px;
    width: 100%;
    background: #fff3d7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    p {
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #333;
        line-height: 30px;
        a {
            color: #3aba50;
            font-size: 12px;
        }
    }
    span {
        position: absolute;
        right: 5%;
        top: 0;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
        i {
            font-size: 12px;
            color: #333;
            padding-left: 5px;
            font-style: normal;
        }
    }
}





@media screen and (max-width: 1200px){
    .center {
        width: 100%;
    }
    .page_banner .page_menu .text {
        padding-top: 9%;
    }
    .dlbox1list .swiper-slide .item {
        width: 100%;
    }

    
}

@media screen and (max-width: 1020px){
    .header {
        display: none;
    }
    .m_header {
        display: block;
    }
    .pc_banner {
        display: none;
    }
    .m_banner {
        display: block;
    }
    .page_banner {
        margin-top: 60px;
    }
    .mtop {
        margin-top: 60px;
    }
    .i_box1 {
        padding: 20px 0;
    }
    .i_box1 .box .fl {
        width: 96%;
        margin: 0 2%;
        padding: 20px;
    }
    .i_box1 .box .fr {
        width: 96%;
        margin: 0 2%;
        margin-top: 10px;
    }
    .i_box1 .box .fr .pic img {
        display: none;
    }
    .i_box1 .box .fr .text {
        width: 100%;
    }
    .i_box3 .box a:first-child + a {
        margin: 0 5%;
    }
    .box9list .swiper-slide .pic {
        height: auto;
    }
    .box11list {
        margin-top: 20px;
        padding: 0;
    }
    .page_banner .page_menu .text {
        padding-top: 5%;
    }
    .page_banner .page_menu .text h3 {
        font-size: 24px;
    }
    .page_banner .page_menu .text span {
        margin: 12px auto;
    }
    .page_banner .page_menu .text p {
        font-size: 18px;
    }
    .cont_box1 .box .fl {
        width: 100%;
    }
    .cont_box1 .box .fr {
        width: 100%;
        margin-top: 20px;
    }
}

@media screen and (max-width: 768px){
    .main {
        overflow: hidden;
    }
    .box_tit span {
        font-size: 30px;
    }
    .i_box2 {
        padding: 30px 2%;
    }
    .i_box5 {
        padding: 30px 2%;
    }
    .box5list .preview {
        padding: 0 20px;
    }
    .box5list .preview .swiper-wrapper {
        display: block;
        transform: translate3d(0, 0px, 0px) !important;
    }
    .box5list .preview .swiper-slide {
        width: 33.33%;
    }
    .box5list .preview .swiper-slide .text {
        width: 96%;
        margin: 0 2%;
    }
    .box5list .view .swiper-slide .pic {
        display: none;
    }
    .box5list .view .swiper-slide .text {
        width: 100%;
        padding: 5%;
    }
    .i_box6 {
        padding: 30px 0;
    }
    .i_box6 .box {
        padding-top: 20px;
    }
    .i_box6 .box .fl {
        display: none;
    }
    .i_box6 .box .fr {
        width: 100%;
    }
    .i_box6 .box .fr li {
        width: 46%;
        margin: 5px 2%;
    }
    .i_box7 {
        padding: 30px 2%;
    }
    .i_box7 .box {
        padding-top: 20px;
    }
    .i_box8 .box {
        padding-top: 20px;
    }
    .i_box8 .box span {
        font-size: 24px;
    }
    .i_box8 .box .text {
        margin-top: 20px;
    }
    .i_box8 .box .text input {
        width: 100%;
        margin-top: 10px;
        height: 36px;
        line-height: 36px;
    }
    .i_box9 {
        padding: 30px 2%;
    }
    .i_box9 .box9_tit .fl span {
        font-size: 30px;
    }
    .box9_content .hd {
        margin-top: 20px;
    }
    .box9list .pagination {
        display: block;
    }
    .i_box10 .box .fl {
        display: none;
    }
    .i_box10 .box .fr {
        width: 100%;
    }
    .i_box10 {
        padding: 30px 2%;
        background: url(../img/bg7.jpg) no-repeat top right;
    }
    .i_box11 {
        padding: 30px 2%;
    }
    .box11list .swiper-slide {
        width: 100%;
    }
    .box11list .pagination {
        display: block;
    }
    .i_box12 {
        padding: 30px 2%;
    }
    .i_box12 .box .fl {
        width: 100%;
        text-align: center;
        padding-top: 0;
    }
    .i_box12 .box .mid {
        width: 100%;
        margin-top: 10px;
    }
    .i_box12 .box .mid img {
        float: none;
        margin: 0 auto;
    }
    .i_box12 .box .fl span {
        text-align: center;
    }
    .i_box12 .box .fl p {
        text-align: center;
    }
    .i_box12 .box .fl i {
        text-align: center;
    }
    .i_box12 .box .fr {
        width: 100%;
    }
    .i_box13 {
        padding: 30px 2%;
    }
    .box13list .hd {
        margin-top: 20px;
    }
    .i_box13 .box13_tit span {
        font-size: 30px;
        text-align: center;
    }
    .i_box13 .box13_tit p {
        text-align: center;
    }
    .i_box14 .box .fl {
        width: 100%;
    }
    .i_box14 .box .fr {
        width: 100%;
        margin-top: 10px;
    }
    .i_box14 {
        padding: 30px 2%;
    }
    .i_box14 .box {
        margin-top: 20px;
        padding: 10px;
    }
    .pp_box1 {
        display: none;
    }
    .pp_box2 {
        padding: 20px 2%;
    }
    .pp_box2 .box {
        margin-top: 20px;
    }
    .pp_box2 .box .fl {
        width: 100%;
    }
    .pp_box2 .box .fr {
        display: none;
    }
    .pp_box3 {
        padding: 20px 2%;
    }
    .pp_box3 .box .fl {
        width: 100%;
    }
    .pp_box3 .box .fr {
        width: 100%;
        padding-left: 0;
        margin-top: 20px;
    }
    .pp_box4 {
        padding: 20px 2%;
    }
    .pp_box4 .box .fl {
        width: 100%;
    }
    .pp_box4 .box .fr {
        width: 100%;
        margin-top: 0;
        padding: 20px;
    }
    .pp_box5 {
        padding: 20px 2%;
    }
    .pp_box5 .box {
        margin-top: 20px;
    }
    .pplist5 .pagination span {
        width: 40px;
        height: 4px;
    }
    .pplist5 .pagination {
        margin-top: 15px;
    }
    .pp_box6 {
        padding: 30px 2%;
    }
    .pp_box6 .box {
        margin-top: 20px;
    }
    .pp_box6 .box .text p {
        width: 100%;
    }
    .pp_box6 .box .list li .fl {
        width: 100%;
    }
    .pp_box6 .box .list li .fr {
        width: 100%;
        border-right: 2px solid #bd111d;
    }
    .pp_box6 .box .list li .fl span {
        display: none;
    }
    .pp_box6 .box .list li {
        border: none;
    }
    .pp_box6 .box .list li:nth-child(2n) {
        border: none;
    }
    .pp_box6 .box .list li:nth-child(2n) .fr {
        border: none;
        border-left: 2px solid #bd111d;
    }
    .jm_box1 {
        padding: 20px 2%;
    }
    .jm_box1 .box {
        margin-top: 20px;
    }
    .jm_box1 .box li {
        width: 100%;
    }
    .jm_box1 .box li .li_box {
        min-height: auto;
    }
    .jm_box1 .box li:first-child .li_box {
        background-size: auto 100%;
    }
    .jm_box1 .box li:first-child +li .li_box {
        background-size: auto 100%;
    }
    .jm_box1 .box li:first-child +li +li .li_box {
        background-size: auto 100%;
    }
    .jm_box1 .box li:first-child +li +li +li .li_box {
        background-size: auto 100%;
    }
    .jm_box2 {
        padding: 30px 2%;
    }
    .jm_box2 .box {
        margin-top: 20px;
    }
    .jm_box2 .box li .fl {
        width: 100%;
        padding: 0;
    }
    .jm_box2 .box li .fr {
        width: 100%;
        padding: 0;
        padding-top: 12px;
    }
    .jm_box2 .box li {
        margin-top: 20px;
    }
    #prolist .box9list .swiper-slide .imgauto {
        height: 190px;
    }
    #prolist {
        padding: 20px 2%;
    }
    .pro_text1 {
        padding: 20px 2%;
    }
    .pro_text1 .box {
        padding: 12px;
    }
    .pro_text1 .box .fl {
        width: 100%;
    }
    .pro_text1 .box .fr {
        width: 100%;
        padding: 12px 0;
    }
    .pro_text2 {
        padding: 0 2%;
        padding-bottom: 20px;
    }
    .pro_text2 .box .text {
        padding: 12px;
        background-size: 100%;
    }
    .i_box12 {
        padding: 20px 2%;
    }
    #pro_fw {
        padding: 20px 2%;
    }
    .i_box12 .box .fr {
        padding: 0;
    }
    .wh_main {
        padding: 20px 2%;
    }
    .wh_main .wh_box {
        padding: 0 20px;
        overflow: hidden;
    }
    .wh_box1 {
        padding: 0;
    }
    .wh_box2 {
        padding: 30px 0;
    }
    .zs_main {
        padding: 20px 2%;
    }
    .zs_main .box {
        padding: 20px;
    }
    .cont_box1 {
        padding: 20px 2%;
    }
    .cont_box1 .box {
        padding: 0;
    }
    .message {
        padding: 20px;
    }
    .message p input {
        width: 100%;
        margin-left: 0;
    }
    .message input {
        margin-bottom: 12px;
    }
    .message .btn_sub {
        margin-top: 20px;
    }
    .dlbox1list .swiper-slide .text p {
        min-height: auto;
    }
    .dlbox1list .swiper-slide {
        padding: 20px 0; 
    }
    .dlbox1list {
        overflow: hidden;
    }
    .dl_box1 {
        padding: 25px 0;
    }
    .dlbox1list {
        margin-top: 20px;
    }
    .dlbox1list .pagination {
        display: block;
    }
    .dl_box2 {
        padding: 25px 2%;
    }
    .dl_box2 .box {
        margin-top: 20px;
        padding: 20px 2%;
    }
    .prd-v5-menu {
        border-left: none;
    }
    .prd-v5-menu li {
        width: 48%;
        height: 40px;
        border: 0;
        margin: 0 1% 15px;
    }
    .prd-v5-menu li select {
        font-size: 14px;
        background-size: 12px auto;
        padding: 0 30px 0 10px;
        background-position: 95% center;
    }
    .prd-v5-menu li button {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
    }
    .prd-v5-map {
        border: none;
    }
    .prd-v5-map .map-list {
        width: 100%;
        border: 1px solid #ddd;
    }
    .prd-v5-map .map-container {
        width: 100%;
        margin-top: 20px;
        border: none;
        padding: 0;
    }
}

@media screen and (max-width: 450px){
    .i_box1 .box .fl {
        padding: 20px 10px;
    }
    .i_box1 .box .fl span {
        font-size: .2rem;
    }
    .i_box1 .box .fl i {
        width: 40px;
        margin-top: 15px;
    }
    .i_box1 .box .fl em {
        font-size: .14rem;
        margin-top: 20px;
    }
    .i_box1 .box .fl p {
        font-size: .115rem;
        padding-right: 0;
        margin-top: 12px;
    }
    .i_box1 .box .fl a {
        width: 129px;
        padding-left: 16px;
        font-size: .115rem;
        line-height: 38px;
        background: url(../img/jt.png) no-repeat 90px center #bd111d;
        margin-top: 12px;
    }
    .i_box1 .box .fr {
        padding: 10px;
    }
    .i_box1 .box .fr .text {
        padding: 40px 10px 10px 10px;
    }
    .i_box1 .box .fr .text p {
        font-size: .115rem;
        line-height: 180%;
    }
    .i_box2 .box span {
        font-size: .2rem;
        padding-bottom: 20px;
    }
    .i_box2 .box p {
        font-size: .115rem;
        line-height: 170%;
        margin-top: 10px;
    }
    .i_box3 {
        height: auto;
        background: url(../img/bg3.jpg) no-repeat center;
    }
    .i_box3 .box {
        padding: 40px 2%;
    }
    .i_box3 .box a {
        width: 32%;
        float: left;
        font-size: .11rem;
        line-height: 42px;
        height: 42px;
    }
    .i_box3 .box a:first-child + a {
        margin: 0 2%;
    }
    .box_tit span {
        font-size: .2rem;
    }
    .box5list .preview .swiper-slide .text p {
        font-size: .1rem;
    }
    .box5list .preview {
        padding: 0 10px;
    }
    .box5list .preview .swiper-slide .text span {
        height: 40px;
        width: 40px;
    }
    .i_box5 .box {
        padding-top: 30px;
    }
    .box_tit p {
        font-size: .11rem;
        margin-top: 12px;
    }
    .box5list .view {
        margin-top: 20px;
    }
    .box5list .view .swiper-slide .text em {
        margin-top: 0;
        width: 50px;
    }
    .box5list .view .swiper-slide .text p {
        font-size: .115rem;
        margin-top: 15px;
    }
    .i_box5 {
        background: url(../img/bg4.png) no-repeat bottom center;
        background-size: 300%;
    }
    .i_box6 .box .fr li span i {
        font-size: .19rem;
    }
    .i_box6 .box .fr li p {
        font-size: .115rem;
    }
    .box7list .swiper-slide .text {
        width: 100%;
    }
    .box7list .swiper-slide .text span {
        font-size: .12rem;
        line-height: 50px;
    }
    .box7list .swiper-slide .text span i {
        font-size: .14rem;
        float: left;
    }
    .box7list .swiper-slide .text p {
        font-size: .115rem;
    }
    .i_box8 .box span {
        font-size: .145rem;
    }
    .i_box8 .box {
        padding: 0 2%;
        padding-top: 30px;
    }
    .i_box8 .box .text input {
        border-color: #ddd;
    }
    .i_box8 .box .text input::-webkit-input-placeholder {
        color: #e7e7e7;
        font-size: 12px;
    }
    .i_box8 .box .text input::-moz-placeholder {
        color: #e7e7e7;
        font-size: 12px;
    }
    .i_box8 .box .text input:-ms-input-placeholder {
        color: #e7e7e7;
        font-size: 12px;
    } 
    .i_box8 .box .text .btn_sub {
        width: 36%;
        font-size: 12px;
    }
    .i_box9 .box9_tit .fl {
        width: 100%;
    }
    .i_box9 .box9_tit .fl span {
        font-size: .2rem;
        text-align: center;
    }
    .i_box9 .box9_tit .fl p {
        font-size: .115rem;
        text-align: center;
    }
    .box9_content .hd ul {
        float: none;
    }
    .box9_content .hd li {
        width: 40%;
        margin: 0 5%;
        float: left;
        font-size: .115rem;
        line-height: 36px;
        height: 36px;
    }
    .box9_content .bd {
        margin-top: 20px;
    }
    .more a {
        width: 129px;
        padding-left: 16px;
        font-size: .115rem;
        line-height: 38px;
        background: url(../img/jt.png) no-repeat 90px center #bd111d;
        margin-top: 12px;
    }
    .i_box10 .box .fr .fr_tit {
        margin-top: 30px;
    }
    .i_box10 .box .fr .text {
        padding: 0;
        padding-top: 20px;
    }
    .i_box10 .box .fr .fr_tit p span {
        font-size: .16rem;
    }
    .i_box10 .box .fr .fr_tit p span i {
        width: 24px;
        height: 4px;
    }
    .i_box10 .box .fr .fr_tit p em {
        padding-left: 32px;
        font-size: .115rem;
    }
    .i_box10 .box .fr .fr_tit p {
        width: 50%;
    }
    .i_box10 .box .fr .text p {
        font-size: .115rem;
        margin-top: 15px;
    }
    .i_box11 .box .text p {
        padding: 10px;
        width: 100%;
        font-size: .11rem;
    }
    .i_box12 .box .fl span {
        font-size: .2rem;
    }
    .i_box12 .box .fl p {
        font-size: .12rem;
        margin-top: 10px;
    }
    .i_box12 .box .fl i {
        font-size: .115rem;
        margin-top: 10px;
    }
    .i_box12 .box .mid img {
        max-width: 50%;
    }
    .i_box12 .box .fr li {
        margin-top: 0;
        margin-bottom: 10px;
        padding: 10px 0;
        padding-left: 90px;
    }
    .i_box12 .box .fr li span {
        font-size: .19rem;
        top: 4px;
    }
    .i_box12 .box .fr li p {
        font-size: .13rem;
    }
    .i_box12 .box .fr li i {
        font-size: .115rem;
    }
    .i_box13 .box13_tit span {
        font-size: .2rem;
        text-align: center;
    }
    .box13list .hd ul li {
        width: 32%;
        float: left;
        margin-left: 2%;
        font-size: .115rem;
        line-height: 36px;
        height: 36px;
    }
    .box13list .hd ul li:first-child {
        margin-left: 0;
    }
    .box13list .hd ul {
        float: none;
    }
    .box13list .bd {
        margin-top: 20px;
    }
    .box13list .bd li {
        width: 49%;
        margin-left: 0;
        margin-right: 2%;
        margin-bottom: 20px;
        display: none;
    }
    .box13list .bd li:nth-child(2n) {
        margin-right: 0;
    }
    .box13list .bd li:first-child {
        display: block;
    }
    .box13list .bd li:first-child +li {
        display: block;
    }
    .box13list .bd li span {
        font-size: .12rem;
        margin-top: 3px;
        line-height: 26px;
    }
    .box13list .bd li p {
        font-size: 12px;
        line-height: 20px;
        height: 60px;
        overflow: hidden;
        padding-left: 25px;
        padding-top: 0;
    }
    .box13list .bd li .text {
        padding: 0 5px;
    }
    .box13list .bd li span i {
        font-size: 15px;
        margin-right: 5px;
        margin-top: 11px;
    }
    .box13list .bd li .time em {
        font-size: 13px;
    }
    .i_box14 .box .fl span {
        font-size: .12rem;
    }
    .i_box14 .box .fl input {
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
    }
    .i_box14 .box .fl textarea {
        height: 80px;
        margin-top: 10px;
    }
    .i_box14 {
        background: url(../img/bg10.jpg) no-repeat top center #150d0c;
    }
    .i_box14 .box .fr {
        padding: 10px;
    }
    .i_box14 .box .fr .text li p {
        font-size: 12px;
    }
    .i_box14 .box .fr .text li span {
        height: .9rem;
    }
    .i_box14 .box .fr .text2 {
        margin-top: 20px;
        padding-top: 20px;
        padding-left: 0;
    }
    .i_box14 .box .fr .text2 p {
        font-size: .12rem;
    }
    .footer .box img {
        max-width: 80%;
        margin: 0 auto;
    }
    .footer {
        padding: 30px 2%;
    }
    .page_banner .page_menu .text {
        padding-top: .23rem;
    }
    .page_banner .page_menu .text h3 {
        font-size: .14rem;
    }
    .page_banner .page_menu .text span {
        margin: .08rem auto;
        width: 120px;
    }
    .page_banner .page_menu .text p {
        font-size: .1rem;
    }
    .pp_tit span {
        font-size: .2rem;
    }
    .pp_tit p {
        font-size: .115rem;
        margin-top: 5px;
    }
    .pp_box2 .box {
        padding: 12px;
    }
    .pp_box2 .box .fl span {
        font-size: .14rem;
        padding: 15px 0;
    }
    .pp_box2 .box .fl p {
        font-size: .115rem;
    }
    .pp_box2 .box .fl .pic {
        margin-top: 16px;
    }
    .pp_box3 .box {
        margin-top: 20px;
    }
    .pp_box3 .box .fl span {
        font-size: .14rem;
        padding: 15px 0;
    }
    .pp_box3 .box .fl p {
        font-size: .115rem;
    }
    .pp_box4 .box .fr h4 {
        font-size: .18rem;
    }
    .pp_box4 .box .fr p span {
        font-size: .13rem;
    }
    .pp_box4 .box .fr p i {
        font-size: .115rem;
        line-height: 180%;
    }
    .pp_box4 .box .fr p {
        padding-bottom: 12px;
    }
    .pp_box4 .box .fr em {
        width: 8px;
        height: 100px;
        left: -4px;
        top: 35%;
    }
    .pp_box4 .box {
        margin-top: 20px;
    }
    .pp_box6 .box .text p {
        padding: 15px;
        font-size: .11rem;
    }
    .pp_box6 .box .list li .fr {
        padding-top: 0;
    }
    .pp_box6 .box .list li .fr i {
        margin: 12px auto;
    }
    .pp_box6 .box .list li .fr p {
        font-size: .115rem;
        line-height: 180%;
    }
    .jm_box1 .box li .li_box {
        padding: 12px;
    }
    .jm_box1 .box li .li_box i {
        margin-left: .1rem;
        font-size: .2rem;
    }
    .jm_box1 .box li .li_box .text {
        padding-left: .35rem;
        padding-top: .08rem;
    }
    .jm_box1 .box li .li_box .text span {
        font-size: .16rem;
    }
    .jm_box1 .box li .li_box .text p {
        font-size: .115rem;
        margin-top: 12px;
    }
    .jm_box2 .box li .fl span {
        font-size: .14rem;
    }
    .jm_box2 .box li .fl p {
        font-size: .115rem;
        margin-bottom: 0;
    }
    .jm_box2 .box li .fl i {
        margin: 10px 0;
    }
    #prolist .box9list .swiper-slide {
        width: 49%;
    }
    #prolist .box9list .swiper-slide:nth-child(3n) {
        margin-right: 2%;
    }
    #prolist .box9list .swiper-slide:nth-child(2n) {
        margin-right: 0;
    }
    #prolist .box9list .swiper-slide .imgauto {
        height: 1.3rem;
    }
    .pro_text1 .box .fr h3 {
        font-size: .15rem;
        padding-bottom: 10px;
    }
    .pro_text1 .box .fr p {
        font-size: .115rem;
        line-height: 180%;
        margin-top: 5px;
    }
    .pro_text2 .box .text span {
        font-size: .13rem;
        padding-bottom: 8px;
    }
    .content p {
        font-size: .11rem;
        line-height: 180%;
        margin-top: 10px;
    }
    #artlist {
        padding: 20px 2%;
    }
    #artlist .bd li {
        display: block;
    }
    #artlist .bd li:nth-child(2n) {
        margin-right: 0;
    }
    #artlist .bd li:nth-child(3n) {
        margin-right: 2%;
    }
    .wh_box1 {
        padding-top: 30px;
    }
    .wh_box1 .box {
        margin-top: 20px;
    }
    .wh_box1 .text p {
        font-size: .115rem;
    }
    .wh_box1 .pic span {
        padding-left: 0;
        width: 48%;
        margin-left: 4%;
    }
    .wh_box1 .pic span:first-child {
        margin-left: 0;
    }
    .wh_box1 .pic {
        margin-top: 20px;
        padding-bottom: 30px;
    }
    .wh_box2 .pp_tit span {
        font-size: .18rem;
    }
    .pp_tit span:before {
        background: none;
    }
    .pp_tit span:after {
        background: none;
    }
    .wh_box2 .text {
        margin-top: 20px;
    }
    .zs_main .list li .fl {
        width: 100%;
        padding: 0;
    }
    .zs_main .list li .fr {
        width: 100%;
        padding: 0;
        margin-top: 12px;
    }
    .zs_main .list li:nth-child(2n) .fl {
        padding-left: 0;
    }
    .zs_main .list li .li_bg {
        padding-top: 35px;
    }
    .zs_main .list li {
        margin-bottom: 35px;
    }
    .zs_main .list li .fl h4 {
        font-size: .13rem;
    }
    .zs_main .list li .fl span {
        font-size: .115rem;
    }
    .zs_main .list li .fl em {
        margin-top: 12px;
        margin-bottom: 16px;
    }
    .zs_main .list li .fl p {
        font-size: .11rem;
        line-height: 160%;
        margin-top: 5px;
    }
    .zs_main .list li .fl i {
        font-size: .115rem;
        margin-top: 10px;
        line-height: .2rem;
    }
    .cont_box1 .box .fr li span {
        font-size: .118rem;
    }
    .cont_box1 .box .fr li .pic img {
        height: 30px;
        width: auto;
    }
    .cont_box1 .box .fr li {
        margin-bottom: 15px;
    }
    .cont_box1 .box .fr li p {
        font-size: .11rem;
        padding: 0 5%;
        line-height: .18rem;
        min-height: .36rem;
    }
    .message {
        padding: 12px;
    }
    .message .btn_sub {
        width: 55%;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
    }
    .message input {
        height: 40px;
        line-height: 40px;
    }
    .message textarea {
        height: 80px;
    }
    .dlbox1list .swiper-slide .text span {
        font-size: .13rem;
    }
    .dlbox1list .swiper-slide .item {
        padding: 0 2%;
    }
    .dlbox1list .swiper-slide .pic {
        width: 40px;
        height: 40px;
        left: 2%;
    }
    .dlbox1list .swiper-slide .text {
        padding-left: 52px;
    }
    .dlbox1list .swiper-slide .text p {
        font-size: .11rem;
        margin-top: 8px;
    }
    .prd-v5-menu {
        margin-bottom: 0;
    }
    .prd-v5-map .map-list {
        height: 300px;
    }
    .prd-v5-map .map-container {
        height: 300px;
        padding: 5px;
        border: 1px solid #ddd;
        margin-top: 5px;
    }
    .prd-v5-map .map-list li a {
        padding: 20px 12px;
    }
    .prd-v5-map .map-list li h4 {
        font-size: .12rem;
        background: url(../img/dic1.png) no-repeat left 3px;
    }
    .prd-v5-map .map-list li p {
        font-size: .11rem;
        margin-top: 8px;
    }
}




/****************************** media ******************************/
